import { defineStore } from 'pinia'
import { globals, cars, teamMembers } from '#nuxt-prepare'
import { watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

export const useStore = defineStore('store', () => {
    const quoteBuilder = globals.quote_builder

    const salary = ref(quoteBuilder.salary_amounts.default_amount)
    const budget = ref(0, 0)
    const weeklyBudget = ref(100, 0)
    const kmsPerYear = ref(quoteBuilder.kms_per_year.default)
    const leaseLength = ref(quoteBuilder.lease_length.default)
    const selectedCar = ref(null)
    const selectedMake = ref('All')
    const selectedQuote = ref(null)
    const postcode = ref(null)
    const quotes = ref({})

    const lowestCarPrice = () => {
        let lowest = Number.MAX_SAFE_INTEGER

        cars.forEach((car) => {
            if (car.price.from < lowest) {
                lowest = car.price.from
            }
        })

        if (lowest == Number.MAX_SAFE_INTEGER) {
            return 0
        }

        return lowest
    }

    const highestCarPrice = () => {
        let highest = 0

        cars.forEach((car) => {
            const carHighest = car.price.to || car.price.from

            if (carHighest > highest) {
                highest = carHighest
            }
        })

        return highest
    }

    const availableCars = () => {
        // Calculate the maximum amount that can be paid off in 5 years
        const maxTermInYears = 5
        const weeksInYear = 52
        const maxAmount = weeklyBudget.value[1] * weeksInYear * maxTermInYears

        // Filter the cars based on the calculated maximum amount
        const affordableCars = cars.filter((car) => car.price.from <= maxAmount)

        // Filter the cars based on the selected make
        if (selectedMake.value && selectedMake.value !== 'All') {
            return affordableCars.filter((car) => car.make.toUpperCase() === selectedMake.value.toUpperCase())
        }

        // Return the filtered list of cars
        return affordableCars
    }

    const carMakes = () => {
        // Return unique car makes in alphabetical order
        if (!cars) {
            return []
        }

        return [...new Set(cars.map((car) => car.make || ''))].sort()
    }

    const state = () => {
        if (!postcode.value) {
            return null
        }

        postcode.value = parseInt(postcode.value)

        for (const [state, ranges] of Object.entries(statePostcodeRanges())) {
            for (const range of ranges) {
                if (postcode.value >= range.min && postcode.value <= range.max) {
                    return state
                }
            }
        }
    }

    const teamMembersInState = () =>
        teamMembers.filter(
            (member) => member.locations.filter((location) => location.title == state()).length > 0,
        )

    const isCarInBudget = (car) => {
        if (!car) {
            return false
        }

        const carHighest = car.price.to || car.price.from

        return carHighest >= budget.value[0] && car.price.from <= budget.value[1]
    }

    const isSalaryWithinLimits = () => {
        return (
            salary.value >= quoteBuilder.salary_amounts.lower_limit &&
            salary.value <= quoteBuilder.salary_amounts.upper_limit
        )
    }

    const getSimilarlyPricedCars = (numberOfCarsToGet = 4) => {
        if (!selectedCar.value) {
            return null
        }

        const carsSortedByPrice = availableCars().toSorted((a, b) => {
            return Math.sign(a.price.from - b.price.from)
        })

        const selectedCarIndex = carsSortedByPrice.findIndex((car) => car.title == selectedCar.value.title)

        carsSortedByPrice.splice(selectedCarIndex, 1)

        const offset = Math.floor(numberOfCarsToGet / 2)
        const indexFrom = Math.max(selectedCarIndex - offset, 0)
        const indexTo = indexFrom + numberOfCarsToGet

        return carsSortedByPrice.slice(indexFrom, indexTo)
    }

    const getQuotesForSelectedCar = () => {
        if (!selectedCar.value || !isSalaryWithinLimits()) {
            console.log('no car selected or salary out of range')
            // no car selected
            return null
        }

        const values = {
            carPrice: parseInt(selectedCar.value.price.from),
            salary: parseInt(salary.value),
            kmsPerYear: parseInt(kmsPerYear.value),
        }

        quotes.value[selectedCar.value.title] = quotes.value[selectedCar.value.title] || {}

        quotes.value[selectedCar.value.title][values.salary] =
            quotes.value[selectedCar.value.title][values.salary] || {}

        quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear] =
            quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear] || {}

        const quoteRequests = []

        for (
            let leaseLengthYears = quoteBuilder.lease_length.lower_limit;
            leaseLengthYears <= quoteBuilder.lease_length.upper_limit;
            leaseLengthYears++
        ) {
            if (
                quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear].hasOwnProperty(
                    leaseLengthYears,
                ) &&
                !quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear][
                    leaseLengthYears
                ].hasOwnProperty('error')
            ) {
                // we already have a quote for this lease length
                continue
            }

            quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear][leaseLengthYears] = {}

            // Add the quote request to the list of requests.
            quoteRequests.push(
                useStatamic()
                    .apiPost('quote', {
                        ...values,
                        leaseLength: leaseLengthYears,
                    })
                    .then((response) => {
                        quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear][
                            leaseLengthYears
                        ] = response.quote
                    })
                    .catch((error) => {
                        console.error(`Unable to fetch quote #${leaseLengthYears}:`, error)

                        quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear][
                            leaseLengthYears
                        ].error = error
                    }),
            )
        }

        if (quoteRequests.length > 0) {
            // If the quote requests are not already cached, wait for them to complete.
            Promise.all(quoteRequests).then(() => {
                selectedQuote.value =
                    quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear][leaseLength.value]
            })
        } else {
            // If the quote requests are already cached, set the selected quote immediately.
            selectedQuote.value =
                quotes.value[selectedCar.value.title][values.salary][values.kmsPerYear][leaseLength.value]
        }
    }

    const selectCar = (car) => {
        selectedCar.value = car
        selectedQuote.value = null
        getQuotesForSelectedCar()
    }

    const clearCar = () => {
        selectedCar.value = null
        selectedQuote.value = null
    }

    const clearQuotes = () => {
        quotes.value = {}
        selectedQuote.value = null
    }

    const updateSelectedQuote = () => {
        selectedQuote.value = null
        getQuotesForSelectedCar()
    }

    const haveQuotesForSelectedCar = () => {
        if (!selectedCar.value) {
            return false
        }

        return quotes.value.hasOwnProperty(selectedCar.value.title)
    }

    // watch budget min/max for changes
    watch(budget, () => {
        // deselect selected car if it falls outside the budget
        if (!isCarInBudget(selectedCar.value)) {
            clearCar()
        }
    })

    const updateKmsPerYear = useDebounceFn(() => {
        updateSelectedQuote()
    }, 600)

    const updateSalary = useDebounceFn(() => {
        updateSelectedQuote()
    }, 750)

    const updateLeaseLength = useDebounceFn(() => {
        updateSelectedQuote()
    }, 100)

    watch(kmsPerYear, updateKmsPerYear)
    watch(salary, updateSalary)
    watch(leaseLength, updateLeaseLength)

    budget.value = [prettyRoundDown(lowestCarPrice()), prettyRoundUp(highestCarPrice())]
    weeklyBudget.value = [
        prettyRoundDown(lowestCarPrice() / 260, 100),
        prettyRoundUp(highestCarPrice() / 260, 100),
    ]

    return {
        salary,
        budget,
        weeklyBudget,
        kmsPerYear,
        leaseLength,
        selectedCar,
        selectedMake,
        selectedQuote,
        postcode,
        quotes,
        lowestCarPrice,
        highestCarPrice,
        availableCars,
        carMakes,
        state,
        teamMembersInState,
        getSimilarlyPricedCars,
        getQuotesForSelectedCar,
        selectCar,
        clearCar,
        haveQuotesForSelectedCar,
    }
})
